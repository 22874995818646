<template>

  <!-- VERSÃO DESKTOP -->
  <div class="loginDesktop flex column" v-if="!isMobile" style="height: 100vh; width: 100vw">
    <div class="form col-12 flex items-center q-ml-md">
    <!-- <div class="form col-12 flex justify-center items-center"> -->
      <div>
        <div class="bgColorFormMobile q-pa-xs">
        <!-- <div class="bgColorFormMobile q-pa-md"> -->
          <div class="title q-mt-xl">
            <div class="column">
            <!-- <div class="row justify-center q-py-xl"> -->
                <!-- <q-img
                  src="../../assets/logo_gestaosistema.png"
                  height="auto"
                  width="300px"
                /> -->

              <p class="text-h3 text-bold text-white nomeEmpresa">Fature</p>
              <p class="text-h3 text-bold text-white nomeEmpresa">Eletrônicos</p>
            </div>
          </div>
          <div class="q-gutter-y-md q-mt-xl q-px-xl" style="width: 30vw">
            <q-input
              outlined
              bg-color="white"
              color="verde"
              v-model="mail"
              type="email"
              label="E-Mail"
            />
            <q-input
              outlined
              color="verde"
              bg-color="white"
              v-model="pass"
              :type="isPwd ? 'password' : 'text'"
              label="Senha"
              @keypress.enter="onAccess"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility' : 'visibility_off'"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
          </div>
          <div class="btns q-my-lg q-px-xl q-mx-xl">
            <q-btn glossy class="full-width text-black" color="white" label="Acessar" @click="onAccess" />
          </div>
        </div>

        <div class="suporte">
          <q-chip
            class="bg-green-3"
            icon="person"
            label="Posso te ajudar?"
            v-model="msgWhats"
            @click="onRedirectWhats"
            removable
            @remove="msgWhats = false"
          />
          <q-avatar
            class="cursor-pointer"
            size="50px"
            font-size="40px"
            text-color="green"
            icon="fab fa-whatsapp"
            @click="onRedirectWhats"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- VERSÃO MOBILE -->
  <div class="loginMobile flex column" v-else style="height: 100vh; width: 100vw">
    <div class="form col-12 flex justify-center q-mt-xl">
    <!-- <div class="form col-12 flex justify-center items-center"> -->
      <div>
        <div class="bgColorFormMobile q-pa-xs">
          <div class="title q-mt-xl">
            <div class="column">
            <!-- <div class="row justify-center q-py-lg"> -->
              <!-- <q-img
                src="../../assets/logo_gestaosistema.png"
                height="auto"
                width="200px"
              /> -->
              <p class="text-h3 text-bold text-white nomeEmpresa">Fature</p>
              <p class="text-h3 text-bold text-white nomeEmpresa">Eletrônicos</p>
            </div>
          </div>
          <div class="q-gutter-y-md q-mt-md q-px-sm" style="width: 80vw">
            <q-input
              outlined
              bg-color="white"
              color="verde"
              v-model="mail"
              type="email"
              label="E-Mail"
            />
            <q-input
              outlined
              color="verde"
              bg-color="white"
              v-model="pass"
              :type="isPwd ? 'password' : 'text'"
              label="Senha"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility' : 'visibility_off'"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
          </div>
          <div class="btns q-my-lg q-px-xl q-mx-xl">
            <q-btn glossy class="full-width text-black" color="white" label="Acessar" @click="onAccess" />
          </div>
        </div>

        <div class="suporte">
          <q-chip
            class="bg-green-3"
            icon="person"
            label="Posso te ajudar?"
            v-model="msgWhats"
            @click="onRedirectWhats"
            removable
            @remove="msgWhats = false"
          />
          <q-avatar
            size="50px"
            font-size="40px"
            text-color="green"
            icon="fab fa-whatsapp"
            @click="onRedirectWhats"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL PARA ESQUECI A SENHA -->
  <q-dialog v-model="modal" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h6">Informe seu E-Mail</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <q-input
          dense
          autofocus
          v-model="mailRemmenber"
          @keyup.enter="prompt = false"
        >
          <template v-slot:prepend>
            <q-icon name="alternate_email" class="icons" />
          </template>
        </q-input>
      </q-card-section>

      <q-card-actions align="right" class="justify-between q-px-xl">
        <q-btn flat dense color="negative" label="Cancelar" v-close-popup />
        <q-btn outline glossy dense color="verde" label="Resetar Senha" @click="onRemmenber" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <!-- MODAL PARA COMPLETAR CADASTRO HOTMART -->
  <q-dialog v-model="modalNEW" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h5 text-bold q-mt-lg">
          Seja bem vindo
        </div>
        <div>
          Por favor complete seu cadastro com as seguintes informações:
        </div>
      </q-card-section>
      <q-card-section>
        <div class="text-subtitle2 q-mt-md">Informe seu Nome Completo</div>
        <q-input
          outlined
          dense
          autofocus
          v-model="fullName"
          type="text"
        />

        <div class="text-subtitle2 q-mt-md">Informe seu telefone de Contato</div>
        <q-input
          outlined
          dense
          v-model="phoneContact"
          mask="(##) # ####-####"
        />

        <div class="text-subtitle2 q-mt-md">Informe o Nome da sua Empresa</div>
        <q-input
          outlined
          dense
          v-model="nameEnterprise"
          type="text"
        />
      </q-card-section>

      <q-card-actions align="center" class="justify-between q-px-xl q-mb-xl q-mt-md">
        <q-btn outline glossy dense style="width: 45%" color="negative" label="Cancelar" v-close-popup />
        <q-btn outline glossy dense style="width: 45%" color="positive" label="Finalizar" @click="onFinish" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { Dialog } from 'quasar';
import { supabase } from '../../supabase';
import Slug from '../../helpers/Slug'

export default {
  data() {
    return {
      mail: '',
      pass: '',
      isPwd: true,
      modal: false,

      newAccess: false,
      modalNEW: false,
      fullName: null,
      phoneContact: null,
      nameEnterprise: null,
      msgWhats: true,
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    }
  },
  methods: {
    async onAccess() {
/*
    await supabase.auth.signUp({
      email: this.mail,
      password: this.pass
    })
*/

      try {
        // VALIDAÇÃO DO PREENCHIMENTO DOS CAMPOS
        if(
          this.mail.indexOf('@') <= 0 ||
          this.mail.indexOf('.') <= 0 ||
          this.mail === '' ||
          this.mail === null
        ) {
          throw new Error('Favor verificar seu E-Mail.');
        }

        if(
          this.pass.length < 6 ||
          this.pass === '' ||
          this.pass === null
        ) {
          throw new Error('Favor verificar seu E-Mail e/ou Senha.');
        }

        try {
          const { error } = await supabase.auth.signIn({
            email: this.mail,
            password: this.pass
          });

          if(error) {
            throw new Error('Favor verificar seu E-Mail e/ou Senha.');
          } else {
            let infos = await supabase
              .from('GE_PERFIL_USERS')
              .select('GE_EMAIL, GE_ENTERPRISE, GE_DEFAULTCOLOR, RECNO, DELETED')
              .match({'GE_EMAIL': this.mail})

            if(infos.data.DELETED === '*') {
              Dialog.create({
                title: 'Fature Eletrônicos',
                message: 'Usuário bloqueado, para mais detalhes, favor entrar em contato com o administrador do Sistema!'
              });

              return null;
            }

            //~> Para coletar informações complementares quando novo acesso via HotMart
            if(infos.data[0].GE_ENTERPRISE === 'COLETAR DEPOIS') {
              this.modalNEW = true
              this.newAccess = true

              return null;
            }

            localStorage.enterprise = infos.data[0].GE_ENTERPRISE;
            localStorage.defaultColor = infos.data[0].GE_DEFAULTCOLOR;
            localStorage.recnoClient = infos.data[0].RECNO;

            let { data } = this.getAccess();
            let { hora } = this.getAccess();
            await supabase
              .from('log_acessos')
              .insert({
                email: this.mail,
                empresa: localStorage.getItem('enterprise'),
                data_acesso: data,
                hora_acesso: hora
              })

            this.$router.push('/home');
          }
        } catch (e) {
          Dialog.create({
            title: 'Gestão Eletrônicos',
            message: 'Favor verificar seu E-Mail e/ou Senha.' //e.message|| e.error_description
          });

          return;
        }
  
        
      } catch (err) {
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Favor verificar seu E-Mail e/ou Senha.' //err.message|| err.error_description
        });

        return;
      }

    },
    onShowRemmenber() {
      this.modal = true;
    },
    async onRemmenber() {
      // TODO: Fazer o sistema de reset da senha

      let { data } = await supabase.auth.signUp({
        email: 'wesleyribeiro123@gmail.com',
        password: 'minhasenha'
      })

      console.log(data);

      alert('Senha será reencaminhada por email');
    },
    getAccess() {
      let dt = new Date()
      let dia = (dt.getDate()).toString()
      let mes = (dt.getMonth() + 1 ).toString()
      let ano = (dt.getFullYear()).toString()
      let hor = (dt.getHours()).toString()
      let min = (dt.getMinutes()).toString()

      let data = String(0).repeat(2 - dia.length) + dia + '/' + String(0).repeat(2 - mes.length) + mes + '/' + ano
      let hora = String(0).repeat(2 - hor.length) + hor + ':' + String(0).repeat(2 - min.length) + min

      // console.log(data)
      return {data: data, hora: hora}

    },
    onRedirectWhats() {
      window.open(
        'https://api.whatsapp.com/send?phone=5532984293724&text=Olá, gostaria de ajuda com o Sistema Gestão Esportes!',
        '_blank'
      );
    },
    async onFinish() {
      const slug = Slug(this.nameEnterprise)

      const { data } = await supabase
        .from('GE_PERFIL_USERS')
        .select('*')
        .eq('GE_ENTERPRISE', this.nameEnterprise)

      if(data.length > 0) {
        Dialog.create({
          title: 'Fature Eletrônicos',
          message: 'Já existe uma empresa cadastrada com este nome!',
          cancel: 'Escolher outro Nome',
          ok: false
        })
      } else {
        const Settings = await supabase
          .from('GE_SETTINGS')
          .update({
            'GE_ENTERPRISE': this.nameEnterprise,
            'GE_SLUG': slug
          })
          .match({
            'GE_EMAIL': this.mail
          })

        const Perfil = await supabase
          .from('GE_PERFIL_USERS')
          .update({
            'GE_ENTERPRISE': this.nameEnterprise,
            'GE_RESPONSIBLE': this.fullName,
            'GE_PHONE': this.phoneContact
          })
          .match({
            'GE_EMAIL': this.mail
          })

        if(Settings.error || Perfil.error) {
          Dialog.create({
            title: 'Fature Eletrônicos',
            message: 'Houve um problema na gravação dos dados, por favor repetir o processo!',
            cancel: false,
            ok: true
          }).onOk(() => {
            return ''
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
  .loginDesktop {
    background-image: url('../../assets/bg_login_GE.png');
    /* background-image: url('../../assets/bg_login_GE.png'); */
    background-size: 100vw auto;
  }

  .loginMobile {
    background-image: url('../../assets/bg_login_GE.png');
    /* background-image: url('../../assets/bg_login_GE.png'); */
    background-size: auto 100vh;
  }

  .bgColorFormMobile {
    /* background-color: rgba(13, 46, 29, 0.8); */
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    /* box-shadow: 0px 0px 30px rgb(83, 144, 111) */
    box-shadow: 0px 0px 30px rgb(0, 0, 0)
  }

  .suporte {
    position: fixed;
    right: 10px;
    bottom: 5px;
  }

  .nomeEmpresa {
    font-variant: small-caps;
  }

</style>
